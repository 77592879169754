import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface FileListProps {
  files: File[];
  fileName?: string;
  onDelete?: (file: File) => unknown;
}

function FileList({ files, fileName = undefined, onDelete = undefined }: FileListProps) {
  if (files.length <= 0) {
    return null;
  }
  return (
    <List dense disablePadding>
      {files.map((file) => (
        <ListItem
          key={file.name}
          secondaryAction={onDelete && (
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
              <DeleteIcon />
            </IconButton>
          )}
        >
          <ListItemIcon sx={{ minWidth: 30 }}>
            <DescriptionOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={fileName ?? file.name}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default FileList;

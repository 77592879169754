import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { DocumentPage } from '../routes/pages';

const useOpenDocument = () => {
  const openDocument = useCallback((documentId: string, path?: string) => {
    window.open(generatePath(path ?? DocumentPage.path, { documentId }));
  }, []);

  return { openDocument };
};

export default useOpenDocument;

import React from 'react';
import {
  ButtonGroup,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FooterBanner, FooterLinkGroup } from './footer.styles';
import Logo from '../../../assets/images/global/exeter-logo-alt.svg';
import XIcon from '../../../assets/icons/x-icon.svg';
import LinkedInIcon from '../../../assets/icons/linkedin-icon.svg';
import { DashboardPage, Page } from '../../../routes/pages';

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = (page: Page) => {
    navigate(page.path);
  };

  return (
    <Stack>
      <FooterBanner>
        <Container>
          <IconButton disableFocusRipple disableTouchRipple sx={{ p: 0 }} onClick={() => handleNavigate(DashboardPage)} id="footer-logo">
            <img src={Logo} alt={t('components.footer.logoAlt') ?? ''} />
          </IconButton>
          <Typography><strong>{t('components.footer.tagLine')}</strong></Typography>
        </Container>
      </FooterBanner>
      <Container>
        <Stack sx={{ mb: 2 }}>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" sx={{ marginY: 2 }}>
            <FooterLinkGroup variant="text">
              <Link href="https://dyn.the-exeter.com/download/brochure?code=MSA" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.modernSlaveryAct')}</Link>
              <Link href="https://dyn.the-exeter.com/download/brochure?code=BO-DP" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.inclusion')}</Link>
              <Link href="https://www.the-exeter.com/terms-conditions/" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.terms')}</Link>
              <Link href="https://www.the-exeter.com/privacy-policy/" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.privacy')}</Link>
              <Link href="https://www.the-exeter.com/complaints/" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.complaints')}</Link>
              <Link href="https://www.the-exeter.com/vulnerable-customers-charter/" target="_blank" rel="noopener noreferrer" color="inherit">{t('components.footer.links.vulnerableCustomers')}</Link>
            </FooterLinkGroup>
            <ButtonGroup variant="text" sx={{ gap: 0.5 }}>
              <IconButton sx={{ p: 0 }}>
                <img src={XIcon} alt={t('components.footer.x') ?? ''} width={37} />
              </IconButton>
              <IconButton sx={{ p: 0 }}>
                <img src={LinkedInIcon} alt={t('components.footer.linkedIn') ?? ''} width={37} />
              </IconButton>
            </ButtonGroup>
          </Stack>
          <Typography variant="caption">{t('components.footer.compliance')}</Typography>
        </Stack>
      </Container>
    </Stack>
  );
}

export default Footer;

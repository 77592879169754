import React, { PropsWithChildren } from 'react';
import { BoxProps } from '@mui/material';
import { AppBar } from './app-bar';
import { Footer } from './footer';
import { Container, OutletContainer } from './layout.styles';
import ScrollToTop from '../scroll-to-top';

interface LayoutProps extends PropsWithChildren {
  outletContainerProps?: BoxProps;
}

function Layout({ outletContainerProps = undefined, children }: LayoutProps) {
  return (
    <Container maxWidth={false} disableGutters>
      <ScrollToTop />
      <AppBar />
      <OutletContainer {...outletContainerProps}>
        {children}
      </OutletContainer>
      <Footer />
    </Container>
  );
}

export default Layout;

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  useNavigate,
  useLocation,
  Location,
  useParams,
  generatePath,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar as AppBarStyled,
  LogoContainer,
  MainToolBar,
  Nav,
  NavButton,
  NavContainer,
  Title,
} from './app-bar.styles';
import Logo from '../../../assets/images/global/exeter-logo.svg';
import {
  ClaimDetailsPage,
  ClaimDocumentPage,
  ClaimEvidencePage,
  DashboardPage,
  DocumentPage,
  DocumentsPage,
  Page,
} from '../../../routes/pages';
import useBreakpoint from '../../../hooks/use-breakpoints';
import UserMenu from '../../user-menu';
import NavMenu from './nav-menu';

function isPageActive(location: Location, paths: string[], defaultPath?: string | undefined) {
  return paths.includes(location.pathname) || (defaultPath ? location.pathname.includes(defaultPath) : false);
}

function AppBar() {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isWide = useBreakpoint('sm');
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const handleNavigate = (page: Page) => {
    setOpenMenu(false);
    navigate(page.path);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleLogout = async () => {
    await Auth.signOut({ global: true });
    setOpenMenu(false);
  };

  const filterPages = (pages: Array<string | null>): string[] => pages.filter((page) => !!page) as string[];

  return (
    <AppBarStyled position="relative">
      <MainToolBar disableGutters>
        <LogoContainer>
          <IconButton disableFocusRipple disableTouchRipple onClick={() => handleNavigate(DashboardPage)} id="logo">
            <img src={Logo} height={isWide ? 45 : 40} alt={t('components.appBar.logoAlt') ?? ''} />
          </IconButton>
        </LogoContainer>
        <NavContainer alignItems="flex-start" alignSelf={isWide ? 'end' : 'center'} flex={1}>
          <Title variant="h2" component="span">{t('components.appBar.title')}</Title>
          {isWide && (
            <Nav>
              <NavButton
                color="inherit"
                active={isPageActive(
                  location,
                  filterPages([
                    DashboardPage.path,
                    params?.claimId ? generatePath(ClaimDetailsPage.path, { claimId: params.claimId }) : null,
                    params?.claimId && params?.evidenceId
                      ? generatePath(ClaimEvidencePage.path, { claimId: params.claimId, evidenceId: params.evidenceId }) : null,
                  ]),
                )}
                onClick={() => handleNavigate(DashboardPage)}
              >
                {t('components.appBar.options.claims')}
              </NavButton>
              <NavButton
                color="inherit"
                active={isPageActive(
                  location,
                  filterPages([
                    params?.documentId ? generatePath(DocumentPage.path, { documentId: params.documentId }) : null,
                    DocumentsPage.path,
                    params?.claimId ? generatePath(ClaimDocumentPage.path, { claimId: params.claimId }) : null,
                  ]),
                )}
                onClick={() => handleNavigate(DocumentsPage)}
              >
                {t('components.appBar.options.documents')}
              </NavButton>
            </Nav>
          )}
        </NavContainer>
        {!isWide && (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open menu"
              sx={{ mr: 2 }}
              onClick={toggleMenu}
            >
              {openMenu ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <NavMenu
              PaperProps={{
                sx: (theme) => ({
                  top: 66,
                  width: '100%',
                  height: 'calc(100% - 66px)',
                  justifyContent: 'space-between',
                  borderTop: `1px solid ${theme.palette.common.surface5}`,
                }),
              }}
              variant="persistent"
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              onOpen={() => setOpenMenu(true)}
              onSelectOption={handleNavigate}
              onLogout={handleLogout}
            />
          </>
        )}
        {isWide && (
          <UserMenu username="Test User" sx={{ mr: 2 }} onLogout={handleLogout} />
        )}
      </MainToolBar>
    </AppBarStyled>
  );
}

export default AppBar;

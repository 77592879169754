import React, { useCallback } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ClaimsLayout from '../claims/components/claims-layout';
import { Container } from '../claims/claims.styles';
import BackBar from '../../components/layout/back-bar';
import { ClaimDocumentPage, DashboardPage } from '../pages';
import WelcomeMessage from './components/welcome-message';
import TodoListProgress from './components/todo-list-progress';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableClaimDetail, selectClaimDetailState } from '../../features/ClaimDetailSlice';
import Loader from '../../components/loader';
import ClaimGuideBanner from './components/claim-guide-banner';
import EvidencePanel from './components/evidence-panel';
import documentApi from '../../services/document-api';
import EvidenceGroup from './components/evidence-group';
import UnsolicitedEvidence from './components/unsolicited-evidence';
import { selectMemberStateMember } from '../../features/MemberSlice';
import { getFeatureFlag } from '../../services/claims-helpers';
import { HeaderContainer } from './claim-details.styles';

function ClaimDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { claimId } = useParams();
  const state = useAppSelector(selectClaimDetailState);
  const {
    claim,
    fulfilments,
  } = useAppSelector(selectAvailableClaimDetail);
  const {
    member,
  } = useAppSelector(selectMemberStateMember);
  const isSingleClaim = !!location.state?.isSingleClaim;

  const handleClaimGuideDownload = useCallback(async () => {
    try {
      if (claim?.claimId) {
        const staticClaimGuide: string = getFeatureFlag('staticClaimGuide').toString() as any;
        if (staticClaimGuide === 'true') {
          documentApi.getStaticGuideToClaimingDocument();
        } else {
          window.open(generatePath(ClaimDocumentPage.path, { claimId: claim.claimId }));
        }
      }
    } catch {
      toast('Error downloading Claim Guide', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  }, [claim]);

  if (state.status !== 'available' || (!member)) {
    return (
      <>
        <Loader />
        <Stack alignItems="center" justifyContent="center" alignContent="center" sx={{ width: '100%', height: '100dvh' }}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!claim || !member) {
    navigate(DashboardPage.path);
  }

  return (
    <ClaimsLayout>
      <Container disableGutters maxWidth={false}>
        <BackBar
          backButtonProps={{
            children: t('components.documents.backToClaims'),
            onClick: () => navigate(DashboardPage.path),
          }}
          hideButton={isSingleClaim}
        >
          <Box sx={{ ml: 'auto', mr: 'auto' }}>
            {`${t('common.claim.prefix')}${claimId}`}
          </Box>
        </BackBar>
        <HeaderContainer>
          {claim && <WelcomeMessage claim={claim} />}
        </HeaderContainer>
        <HeaderContainer>
          <ClaimGuideBanner onDownload={handleClaimGuideDownload} />
        </HeaderContainer>
        <Container sx={{ pt: 4, pb: 4 }}>
          <Stack direction="column" gap={4}>
            <TodoListProgress completed={fulfilments.completed} total={fulfilments.total} />
            <Stack direction="column" gap={2}>
              {claim && member && fulfilments.groups.map((group) => (
                <EvidenceGroup group={group} key={group.fulfilmentType}>
                  {group.evidences.map((evidence) => (
                    <EvidencePanel
                      key={evidence.id}
                      evidence={evidence}
                      claim={claim}
                    />
                  ))}
                </EvidenceGroup>
              ))}
              {claim && member && <UnsolicitedEvidence claim={claim} member={member} />}
            </Stack>
          </Stack>
        </Container>
      </Container>
    </ClaimsLayout>
  );
}

export default ClaimDetails;

export const DashboardPage = { path: '/', id: 'Claims' } as const;
export const DocumentsPage = { path: '/documents', id: 'Documents' } as const;
export const DocumentPage = { path: '/documents/:documentId', id: 'Document' } as const;
export const ClaimDetailsPage = { path: '/claims/:claimId', id: 'ClaimDetails' } as const;
export const ClaimDocumentPage = { path: '/claims/:claimId/document', id: 'ClaimDocument' } as const;
export const ClaimEvidencePage = { path: '/claims/:claimId/evidences/:evidenceId', id: 'ClaimEvidence' } as const;

export type PageWithParams =
  typeof DashboardPage |
  typeof DocumentsPage |
  typeof ClaimDetailsPage |
  typeof ClaimDocumentPage |
  typeof ClaimEvidencePage;

export type Page = Pick<PageWithParams, 'id' | 'path'>;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Alert, Button, CircularProgress, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import documentApi from '../../services/document-api';
import useBusyState from '../../hooks/use-busy-state';
import Loader from '../../components/loader';
import { Layout } from '../../components/layout';

enum DocumentError {
  NOT_FOUND,
  DEFAULT,
}

export enum DocumentType {
  EVIDENCE,
  CLAIM,
}
interface DocumentProps {
  type?: DocumentType;
}

function Document({ type = DocumentType.EVIDENCE }: DocumentProps) {
  const { t } = useTranslation();
  const { documentId, claimId } = useParams();
  const [busy, withBusyState] = useBusyState();
  const [errorType, setErrorType] = useState<DocumentError>();

  const downloadDocument = useCallback(withBusyState(async () => {
    if (!documentId && !claimId) return;
    setErrorType(undefined);
    try {
      let blob;
      if (type === DocumentType.CLAIM && claimId) {
        const claimDoc = await documentApi.getGuideToClaimingDocument(claimId);
        blob = new Blob([claimDoc.fileContentBase64String]);
      } else if (documentId) {
        const evidenceDoc = await documentApi.getDocument(documentId);
        blob = new Blob([evidenceDoc.data], { type: evidenceDoc.headers['content-type'] });
      }
      if (!blob) {
        throw new Error('No response');
      }
      const fileURL = URL.createObjectURL(blob);
      window.location.href = fileURL;
    } catch (error: any) {
      if (error?.response?.status === 403 || error?.response?.status === 404) {
        setErrorType(DocumentError.NOT_FOUND);
      } else {
        setErrorType(DocumentError.DEFAULT);
        toast(t('components.document.toast.error'), {
          position: 'bottom-left',
          type: 'error',
        });
      }
    }
  }), [documentId, claimId]);

  useEffect(() => {
    downloadDocument();
  }, [documentId, claimId]);

  const renderContent = () => {
    if (errorType === DocumentError.NOT_FOUND) {
      return <Alert severity="warning">{t('components.document.documentNotFound')}</Alert>;
    }

    if (errorType === DocumentError.DEFAULT) {
      return (
        <Layout>
          <Stack gap={2} sx={{ mt: 2 }}>
            <Typography>{t('components.document.failed')}</Typography>
            <Button variant="contained" onClick={downloadDocument}>{t('common.tryAgain')}</Button>
          </Stack>
        </Layout>
      );
    }

    if (busy) {
      return (
        <>
          <Loader />
          <Stack alignItems="center" justifyContent="center" alignContent="center" sx={{ width: '100%', height: '100dvh' }}>
            <CircularProgress />
          </Stack>
        </>
      );
    }

    return null;
  };

  return renderContent();
}

export default Document;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from '../claims/claims.styles';
import ClaimsLayout from '../claims/components/claims-layout';
import BackBar from '../../components/layout/back-bar';
import { ClaimDetailsPage } from '../pages';
import { EvidenceFormView, MedicalPermissionsForm } from '../../components/evidence-forms';
import { medicalPermissionsFormSchema } from '../../services/evidence-form-helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectMemberStateMember } from '../../features/MemberSlice';
import { getMemberName } from '../../services/member-helpers';
import { selectAvailableClaimDetail, selectClaimDetailState, updateEvidences } from '../../features/ClaimDetailSlice';
import Loader from '../../components/loader';
import documentApi from '../../services/document-api';
import { GenerateEvidenceDocumentWithSignatureDto } from '../../services/models/generate-evidence-document-with-signature-dto';
import { ClaimEvidence as ClaimEvidenceType } from '../../services/models/claim-evidence';
import { Document } from '../../services/models/document';
import evidenceApi from '../../services/evidence-api';
import { EvidenceStatus } from '../../services/evidence-helpers';
import useOpenDocument from '../../hooks/use-open-document';

function getLatestDocumentFromEvidences(evidenceId: string, evidences: ClaimEvidenceType[]): Document | undefined {
  try {
    const evidenceDocument = evidences?.find((evidence) => evidence.id === evidenceId && evidence.documents)?.documents?.at(0);
    return evidenceDocument;
  } catch {
    return undefined;
  }
}

function ClaimEvidence() {
  const { t } = useTranslation();
  const { openDocument } = useOpenDocument();
  const navigate = useNavigate();
  const state = useAppSelector(selectClaimDetailState);
  const { member } = useAppSelector(selectMemberStateMember);
  const { claim, evidences } = useAppSelector(selectAvailableClaimDetail);
  const { evidenceId } = useParams();
  const dispatch = useAppDispatch();
  const policyId = claim?.policyId;

  const document = evidenceId ? getLatestDocumentFromEvidences(evidenceId, evidences) : undefined;

  const handleBackClick = () => {
    if (claim) {
      navigate(generatePath(ClaimDetailsPage.path, { claimId: claim.claimId }));
    }
  };

  const handleDownload = async () => {
    if (document?.id) {
      try {
        openDocument(document.id);
        // await documentApi.openMemberDocument(documentApi.getDocument(document?.id), document.name);
      } catch {
        toast('Error downloading document', {
          position: 'bottom-left',
          type: 'error',
        });
      }
    }
  };

  const handleSave = async (values: GenerateEvidenceDocumentWithSignatureDto) => {
    try {
      if (!evidenceId) {
        throw new Error('No evidence record');
      }
      await documentApi.signEvidenceDocument(evidenceId, { ...values, claimId: claim.claimId });
      await evidenceApi.updateEvidenceStatus(evidenceId, EvidenceStatus.Received);
      toast('Document signed successfully', {
        position: 'bottom-left',
        type: 'success',
      });
      // Add timeout to allow status to propergate on the b/e
      await new Promise((res) => {
        setTimeout(res, 4000);
      });
      await dispatch(updateEvidences(claim.claimId));
      handleBackClick();
    } catch {
      toast('Error signing document', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  };

  if (state.status !== 'available' || (!member)) {
    return <Loader />;
  }

  return (
    <ClaimsLayout>
      <Container disableGutters maxWidth={false} sx={{ backgroundColor: 'common.white' }}>
        <BackBar
          backButtonProps={{
            children: t('components.documents.backToClaims'),
            onClick: handleBackClick,
          }}
        >
          <Box sx={{ ml: 'auto', mr: 'auto', pr: '125px' }} data-testid="claim-id">
            {`${t('common.claim.prefix')}${claim.claimId}`}
          </Box>
        </BackBar>
        {policyId && (
          <EvidenceFormView
            title="Medical Permissions"
            schema={medicalPermissionsFormSchema}
            onDownload={document ? handleDownload : undefined}
            onSave={handleSave}
          >
            <MedicalPermissionsForm
              claimId={claim.claimId}
              policyId={policyId}
              dateOfBirth={member.dateOfBirth}
              name={getMemberName(member)}
            />
          </EvidenceFormView>
        )}
      </Container>
    </ClaimsLayout>
  );
}

export default ClaimEvidence;

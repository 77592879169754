import {
  Box,
  ButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterBanner = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  '& > div': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const FooterLinkGroup = styled(ButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
  '& > a': {
    textDecoration: 'none',
    ...theme.typography.caption,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.text.primary}`,
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& > a': {
      borderRight: 'none',
    },
  },
}));

export default {};
